// import React from 'react'
// import MainCover from '../Components/MainCover'

// const HelpSupport = () => {
//   return (
//     <MainCover>
        
//     </MainCover>
//   )
// }

// export default HelpSupport


import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MainCover from "../Components/MainCover";
import { fetchHelpSupportContent } from "../util/orders/orders";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1a202c",
    },
    secondary: {
      main: "#2d3748",
    },
  },
  typography: {
    fontFamily: "Roboto, sans-serif",
  },
});

const HelpSupport = () => {
  const [helpRequests, setHelpRequests] = useState([]);

  const getHelpRequests = async () => {
    const response = await fetchHelpSupportContent();
    setHelpRequests(response)
  };

  useEffect(() => {
    getHelpRequests();
  }, []);

  return (
    // <ThemeProvider theme={theme}>
      <MainCover>
        <Box sx={{ width: "100%", padding: 2 }}>
          <Typography variant="h5" sx={{ marginBottom: 2, color: "#fff" }}>
            Help and Support Requests
          </Typography>
          <TableContainer component={Paper} sx={{ backgroundColor: "#2d3748" }}>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#1a202c" }}>
                  <TableCell sx={{ color: "#fff" }}>SN</TableCell>
                  <TableCell sx={{ color: "#fff" }}>Order ID</TableCell>
                  <TableCell sx={{ color: "#fff" }}>Timestamp</TableCell>
                  <TableCell sx={{ color: "#fff" }}>Reason</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                helpRequests?.map((request, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ color: "#cbd5e0" }}>{index + 1}</TableCell>
                    <TableCell sx={{ color: "#cbd5e0" }}>{request.orderId}</TableCell>
                    <TableCell sx={{ color: "#cbd5e0" }}>
                      {new Date(Number(request.timestamp)).toLocaleDateString([], {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </TableCell>
                    <TableCell sx={{ color: "#cbd5e0" }}>{request.reason}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </MainCover>
    // </ThemeProvider>
  );
};

export default HelpSupport;

