import { Redirect } from 'react-router-dom';
import { useState } from 'react';
import './App.css';
import firebase from './firebase';
import AdminPanel from './Pages/AdminPanel';
import Login from './Pages/Login';
import Swal from 'sweetalert2';
import { DataLayer } from './DataLayer/DataLayer'
import { ThemeProvider } from '@mui/material/styles';
import reducer, { initialState } from './DataLayer/reducer'
import { lightTheme, darkTheme } from './theme';
import { verifyAdmin } from './util/verifyAdmin';
function App() {
  const [firebaseUserData, setFirebaseUserData] = useState(null)

  firebase.auth().onAuthStateChanged(user => {
    console.log("app", user)
    if (user) {
      setFirebaseUserData(user)
      // firebase.auth().signOut()
    }
    else {
      setFirebaseUserData(undefined)
    }
  })
  if (firebaseUserData === undefined) {
    return (
      <>
        <Login />
        <Redirect to="/" />
      </>
    )

  }
  else {
    if (firebaseUserData !== null) {
      if (
        verifyAdmin(firebaseUserData.uid)
      ) {
        return (
          <DataLayer initialState={initialState} reducer={reducer}>
            <ThemeProvider theme={darkTheme}>

              <AdminPanel />

            </ThemeProvider>
          </DataLayer>)
      }
      else {
        Swal.fire("You are not authorized Admin.", "", "error")
        return (
          <>
            <Login />
            <Redirect to="/" />
          </>
        )
      }
    }
    else {
      console.log(firebaseUserData)
      return ""
    }
  }
}

export default App;
