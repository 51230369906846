import React, { useState } from 'react';
import { Send as SendIcon } from '@mui/icons-material';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import firebase from '../../firebase';

const useStyles = makeStyles({
    chatSection: {
        width: '100%',
        height: '65vh',
        overflowY: 'auto',
    },
    messageArea: {
        padding: '10px',
    },
    messageBox: {
        padding: '10px',
        backgroundColor: '#f1f1f1',
        borderRadius: '10px',
        marginBottom: '10px',
    },
    time: {
        fontSize: '12px',
        color: '#999',
    },
    sendButton: {
        marginTop: '10px',
    },
});

const getTime = (timestamp) => {
    const time = new Date(timestamp);
    const hour = time.getHours();
    const minute = time.getMinutes();
    const calculatedHour = hour > 12 ? hour - 12 : hour;
    const meridian = hour >= 12 ? 'PM' : 'AM';
    const formattedMinute = minute < 10 ? `0${minute}` : minute;

    return `${calculatedHour}:${formattedMinute} ${meridian}`;
};

const Chats = ({ currentUser }) => {
    const [message, setMessage] = useState('');
    const classes = useStyles();

    const sendMessage = () => {
        firebase
            .database()
            .ref(`users/${currentUser.id}/adminMessages`)
            .push({
                text: message,
                createdAt: Date.now(),
                senderName: 'expert',
            })
            .then(() => {
                setMessage('');
            });
    };

    return (
        <Grid container className={classes.messageArea}>
            <Grid item xs={12}>
                <List className={classes.chatSection}>
                    {currentUser?.adminMessages.map((item, index) => (
                        <ListItem key={index}>
                            <ListItemIcon>
                                <Avatar>{item?.senderName?.substring(0, 1) || ''}</Avatar>
                            </ListItemIcon>
                            <div className={classes.messageBox}>
                                <p>{item?.text}</p>
                                <div className={classes.time}>{getTime(item?.timestamp || item?.createdAt)}</div>
                            </div>
                        </ListItem>
                    ))}
                </List>
                <Divider />
                <Grid container>
                    <Grid item xs={11}>
                        <TextField
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            label="Type Something"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <Fab
                            color="primary"
                            size="small"
                            onClick={sendMessage}
                            className={classes.sendButton}
                        >
                            <SendIcon />
                        </Fab>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Chats;
