import AssessmentIcon from '@mui/icons-material/Assessment';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Box, Button, Divider, Drawer, ListItem } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { useDataLayerValue } from '../DataLayer/DataLayer';
import { ChartBar as ChartBarIcon } from '../icons/chart-bar';
import { ShoppingBag as ShoppingBagIcon } from '../icons/shopping-bag';
import { Users as UsersIcon } from '../icons/users';
import './component.css';
import Submenu from './SlideBar/SubMenu';
import { Approval, Chat, Group, Help, TrackChanges } from '@mui/icons-material';
const items = [
    {
        href: '/',
        icon: (<ChartBarIcon />),
        title: 'Dashboard'
    },
    {
        href: '/analytics',
        icon: (<AssessmentIcon />),
        title: 'Analytics'
    },
    {
        href: '/orders',
        icon: (<ShoppingCartIcon />),
        title: 'Orders'
    },
    {
        href: '/order-track',
        icon: (<TrackChanges />),
        title: 'Track orders'
    },
    {
        href: "/chat",
        icon: (<Chat />),
        title: 'Chat'
    },
    {
        href:'/groups',
        icon: (<Group />),
        title: 'Groups'
    },
    {
        href:'/verifyprofile',
        icon: (<Approval />),
        title: 'ApproveProfile'
    },
    {
        href: '/mails',
        icon: (<AttachEmailIcon />),
        title: 'Contact Us'
    },
    {
        href: '/experts-mail',
        icon: (<AttachEmailIcon />),
        title: 'Talk to Expert'
    },
    {
        href: '/enquiry',
        icon: (<AttachEmailIcon />),
        title: 'Bulk query'
    },
    {
        href: '/help-desk',
        icon: (<SupportAgentIcon />),
        title: 'Help Desk'
    },
    {
        href: '/help&support',
        icon: (<Help />),
        title: 'Help & support'
    }
];
const productSubItems = [
    {
        href: "/products/all",
        name: "Products For Sell",
    },
    {
        href: "/products/rented",
        name: "Products For Rent",
    },
    {
        href: "/products/verify",
        name: "Items To Verify",
    },
    {
        href: "/products/categories",
        name: "Categories",
    },
    {
        href: "/products/crops-and-item",
        name: "Crops & Items",
    }
]
const usersSubItems = [
    {
        href: "/users/all",
        name: "All Users",
    },
    {
        href: "/users/farmers",
        name: "Farmers",
    },
    {
        href: "/users/corporates",
        name: "Corporates",
    },
]

const NavItem = (props) => {
    const { href, icon, title, ...others } = props;
    const history = useHistory();
    const [{ drawer }, dispatch] = useDataLayerValue()
    const active = href ? (window.location.pathname === href) : false;
    const handleClick = () => {
        history.push(`${href}`)
        dispatch({ type: "SET_DRAWER", data: false })
    }
    return (
        <ListItem
            disableGutters
            sx={{
                display: 'flex',
                mb: 0.5,
                py: 0,
                // px: 2
            }}
            {...others}
        >

            <Button
                component="a"
                startIcon={icon}
                // disableRipple
                onClick={handleClick}
                fullWidth
                sx={{
                    backgroundColor: active && 'rgba(255,255,255, 0.08)',
                    borderRadius: 1,
                    color: active ? 'secondary.main' : 'neutral.300',
                    fontWeight: active && 'fontWeightBold',
                    justifyContent: 'flex-start',
                    // px: 3,
                    textAlign: 'left',
                    textTransform: 'none',
                    width: '100%',
                    '& .MuiButton-startIcon': {
                        color: active ? 'secondary.main' : 'neutral.400'
                    },
                    '&:hover': {
                        backgroundColor: 'rgba(255,255,255, 0.08)',
                        color: active ? 'secondary.main' : 'neutral.300',
                    }
                }}
            >
                <Box sx={{ flexGrow: 1 }}>
                    {title}
                </Box>
            </Button>

        </ListItem>
    );
};

NavItem.propTypes = {
    href: PropTypes.string,
    icon: PropTypes.node,
    title: PropTypes.string
};

function SlideMenu() {
    const [{ drawer }, dispatch] = useDataLayerValue()

    const content = (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    px: 3
                }}
            >
                <Divider
                    sx={{
                        borderColor: '#2D3748',
                        my: 3
                    }}
                />
                <Box sx={{ flexGrow: 1 }}>
                    {items.map((item) => (
                        <NavItem
                            key={item.title}
                            icon={item.icon}
                            href={item.href}
                            title={item.title}
                        />
                    ))}
                    <Submenu list={productSubItems} icon={<ShoppingBagIcon />} name="Products" />
                    <Submenu list={usersSubItems} icon={<UsersIcon />} name="Users" />
                    <Submenu list={[
                        {
                            href: "/analytics",
                            name: "Overall",
                        },
                        {
                            href: "/analytics/state",
                            name: "State",
                        },
                        {
                            href: "/analytics/district",
                            name: "District",
                        },
                        {
                            href: "/analytics/block",
                            name: "Block",
                        },

                    ]} icon={<AssessmentIcon />} name="Analytics" />

                </Box>

            </Box>
        </>
    );
    return (
        <div className="slide-menu">
            <Drawer
                variant={window.innerWidth > 600 ? "permanent" : "temporary"}
                anchor="left"
                onClose={() => {
                    dispatch({
                        type: 'SET_DRAWER',
                        data: false
                    })
                }}
                open={drawer}
                PaperProps={{
                    sx: {
                        backgroundColor: 'neutral.900',
                        color: '#FFFFFF',
                        width: 280,
                        borderRightColor: "rgb(45, 55, 72)"
                    }
                }}
                sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
            >
                {content}
            </Drawer>
        </div>
    )
}

export default SlideMenu
