import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  Paper,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import MainCover from "../Components/MainCover";
import { fetchAllOrders, sendMessage } from "../util/orders/orders";
import Swal from "sweetalert2";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1a202c",
    },
    secondary: {
      main: "#2d3748",
    },
  },
  typography: {
    fontFamily: "Roboto, sans-serif",
  },
});

const OrderTrackUI = () => {
  const [orderTracks, setOrderTracks] = useState([]);

  const getOrderTrack = async () => {
    const data = await fetchAllOrders();
    const allOrders = Object.values(data);
    const sortedOrders = allOrders.sort((a, b) => b.TIMESTAMP - a.TIMESTAMP)
    // console.log('sorted orders', sortedOrders)
    setOrderTracks(sortedOrders)
  }

  useEffect(() => {
    getOrderTrack()
  }, [])

  const [currentOrder, setCurrentOrder] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [triggeredOrder, setTriggeredOrder] = useState(null)

  const handleViewMessages = (order) => {
    setTriggeredOrder(order)
    setCurrentOrder(Object.values(order?.requests[0]?.messages || {}));
  };

  const handleCloseDialog = () => {
    setTriggeredOrder(null)
    setCurrentOrder(null);
  };

  const handleSendMessage = async () => {
    if (!newMessage.trim()) {
      return;
    }
    const message = {
      userId: 'admin',
      message: newMessage,
      timestamp: new Date().toISOString(),
      admin: true
    }
    try {
      await sendMessage(triggeredOrder?.id, message)
      // console.log(response)
      setNewMessage("");
      getOrderTrack()
      handleViewMessages()
    } catch (err) {
      Swal.fire('error sending the message !',
        '',
        'warning')
      setNewMessage("");
    }
  };

  // console.log('order', order)

  return (
    <MainCover>
      {/* Order Table */}
      <Box sx={{ width: "100%", padding: 2 }}>
        <TableContainer component={Paper} sx={{ backgroundColor: "#2d3748" }}>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#1a202c" }}>
              <TableCell sx={{ color: "#fff" }}>SN.</TableCell>
                <TableCell sx={{ color: "#fff" }}>Order ID</TableCell>
                <TableCell sx={{ color: "#fff" }}>Item</TableCell>
                <TableCell sx={{ color: "#fff" }}>Order Date</TableCell>
                <TableCell sx={{ color: "#fff" }}>Seller Name</TableCell>
                <TableCell sx={{ color: "#fff" }}>Buyer Name</TableCell>
                <TableCell sx={{ color: "#fff" }}>Messages</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderTracks.map((order,index) => (
                <TableRow key={order.id}>
                  <TableCell sx={{ color: "#cbd5e0" }}>{index + 1}</TableCell>
                  <TableCell sx={{ color: "#cbd5e0" }}>{order?.orderId}</TableCell>
                  <TableCell sx={{ color: "#cbd5e0" }}>{order.itemName}</TableCell>
                  <TableCell sx={{ color: "#cbd5e0" }}>{new Date(Number(order.TIMESTAMP)).toLocaleDateString([], {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  })}</TableCell>
                  <TableCell sx={{ color: "#cbd5e0" }}>{order.sellerName}</TableCell>
                  <TableCell sx={{ color: "#cbd5e0" }}>{order.buyerName}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleViewMessages(order)}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Messages Dialog */}
      <Dialog
        open={!!triggeredOrder}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="sm"
        scroll="paper"
      >
        <DialogTitle sx={{ backgroundColor: "#1a202c", color: "#fff" }}>
          Messages for Order #{currentOrder?.id}
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: "#2d3748", color: "#fff" }}>
          {
            currentOrder&&
            currentOrder?.map((message, index) => (
              <Box
                key={index}
                sx={{
                  padding: 2,
                  margin: "10px 0",
                  backgroundColor: "#28282B",
                  borderRadius: 2,
                }}
              >
                <Typography variant="body2" color="white">
                  <strong>{message?.userId}</strong> {/* Replace with actual user name if available */}
                </Typography>
                <Typography variant="body1" color="lightgrey" sx={{ marginTop: 1 }}>
                  {message?.message}
                </Typography>
                <Typography
                  variant="caption"
                  color="lightgrey"
                  sx={{ marginTop: 1, display: "block" }}
                >
                  {new Date(message?.timestamp).toLocaleString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })}
                </Typography>
              </Box>
            ))
          }
          <Box sx={{ display: "flex", flexDirection: "column", marginTop: 2 }}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Type your message..."
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              sx={{ marginBottom: 2 }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSendMessage}
              sx={{ alignSelf: "flex-end" }}
            >
              Send
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </MainCover>
  );
};

export default OrderTrackUI;
