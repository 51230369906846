import firebase from '../../firebase';


export const getMessagesBySellerId = async (sellerId) => {
    try {
        if (!sellerId) {
            throw new Error("Seller ID is required.");
        }

        const messagesRef = firebase.database().ref("expert_chats");
        const snapshot = await messagesRef.orderByChild("sellerUID").equalTo(sellerId).once("value");

        if (!snapshot.exists()) {
            console.log("No messages found for the given seller ID.");
            return [];
        }

        // Convert Firebase snapshot to an array
        const messagesArray = [];
        snapshot.forEach(childSnapshot => {
            messagesArray.push({ id: childSnapshot.key, ...childSnapshot.val() });
        });

        return messagesArray;
    } catch (error) {
        console.error("Error retrieving messages:", error);
        return [];
    }
};


const fetchChats = () => {
    return new Promise(resolve => {
        firebase.database().ref('users/').on('value', (snapshot) => {
            const rawUserData = []
            var snapVal = snapshot.val();
            for (let id in snapVal) {
                var currentUser = snapVal[id]
                if (currentUser.direct_messages) {
                    const direct_messages = Object.values(currentUser.direct_messages)
                    rawUserData.push({ id: id, name: currentUser.name, phone: currentUser.phone, direct_messages })
                }
            }
            rawUserData.sort((a, b) => {
                return b.direct_messages[b.direct_messages.length - 1].timestamp - a.direct_messages[a.direct_messages.length - 1].timestamp
            })
            resolve(rawUserData)
        })
    })
}

export default fetchChats