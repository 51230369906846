import Swal from 'sweetalert2';
import firebase from "../../firebase";

export const showAllGroups = async () => {
        try {
            const snapshot = await firebase.database().ref("groups").once("value");
            if (snapshot.exists()) {
                const groups = [];
                snapshot.forEach((childSnapshot) => {
                    groups.push({
                        id: childSnapshot.key,
                        ...childSnapshot.val(),
                    });
                });
                return groups;
            }
            return []; // Return empty array if no groups exist
        } catch (error) {
            console.error("Error fetching groups:", error);
            return []; // Return empty array in case of an error
        }
};



export const showSingleGroup = async (groupId) => {
    try {
        const snapshot = await firebase.database().ref("groups").child(groupId).once("value");
        if (snapshot.exists()) {
            const groupData = snapshot.val();
            return groupData;
        } else {
            return null; // Return null if group does not exist
        }
    } catch (error) {
        console.error("Error fetching group data:", error);
        return null; // Return null in case of an error
    }
};

export const fetchMessages = async (groupId) => {
    try {
        const snapshot = await firebase.database().ref(`groups/${groupId}/messages`)
          .orderByChild('createdAt')
          .once('value');
    
        if (snapshot.exists()) {
          const messages = [];
          snapshot.forEach((childSnapshot) => {
            messages.push({
              id: childSnapshot.key,
              ...childSnapshot.val(),
            });
          });
          return messages;  // Return the messages array
        } else {
          console.log("No messages found");
          return [];  // Return an empty array if no messages exist
        }
      } catch (error) {
        console.error("Error fetching messages:", error);
        return [];  // Return an empty array in case of error
      }
  };


  export const sendMessage = async (groupId, message, sender) => {
    try {
      const messageData = {
        text: message,
        sender: {
          uid: sender?.uid || null,
          name: "Admin",
          photoURL: sender.photoURL || null,
        },
        createdAt: firebase.database.ServerValue.TIMESTAMP,
        status: "sent",
      };
  
      const newMessageRef = await firebase.database()
        .ref(`groups/${groupId}/messages`)
        .push(messageData);
  
      const messageId = newMessageRef.key;

      const updates = {
        [`groups/${groupId}/lastMessage`]: {
          text: message,
          senderId: sender?.uid || null,
          senderName: "Admin",
          createdAt: firebase.database.ServerValue.TIMESTAMP,
        },
        [`groups/${groupId}/updatedAt`]: firebase.database.ServerValue.TIMESTAMP,
      };
  
      await firebase.database().ref().update(updates);
  
      return {
        id: messageId,
        ...messageData,
      };
    } catch (error) {
      console.error("Error sending message:", error);
      return null; // Return null in case of an error
    }
  };
