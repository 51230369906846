import firebase from "../firebase";

export const fetchUnapprovedUsers = async () => {
  try {
    const snapshot = await firebase.database().ref("users").once("value");

    if (snapshot.exists()) {
      const unapprovedUsers = [];
      snapshot.forEach((childSnapshot) => {
        const userData = childSnapshot.val();
        if (userData.pfp && userData.pfp.isApproved === false) {
          unapprovedUsers.push({
            id: childSnapshot.key,
            ...userData,
          });
        }
      });
      return unapprovedUsers;
    } else {
      console.log("No users found.");
      return [];
    }
  } catch (error) {
    console.error("Error fetching unapproved users:", error);
    return []; 
  }
};


export const removeUserPfp = async (userId) => {
  try {
    const userRef = firebase.database().ref(`users/${userId}/pfp`);
    await userRef.set(null);

    console.log(`Successfully removed the pfp field for user with id: ${userId}`);
    return true;
  } catch (error) {
    console.error(`Error removing pfp field for user with id: ${userId}`, error);
    return false;
  }
};

export const approveUser = async (userId) => {
  try {
    const userRef = firebase.database().ref(`users/${userId}/pfp/isApproved`);
    await userRef.set(true);

    console.log(`Successfully approved user with id: ${userId}`);
    return true;
  } catch (error) {
    console.error(`Error approving user with id: ${userId}`, error);
    return false;
  }
};
