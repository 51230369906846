import { Box, Button, TextField, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import SecondMenu from '../Components/SecondMenu';
import SlideMenu from '../Components/SlideMenu';
import { useDataLayerValue } from '../DataLayer/DataLayer';
import firebase from '../firebase';
import ReplyIcon from '@mui/icons-material/Reply';
import CallIcon from '@mui/icons-material/Call';
import { TimeStampToDate } from '../functions/TimeStamp';
/* eslint-disable */
function ContactMails() {
    const [data, setData] = useState([]);


    // useEffect(() => {
    //     const loadData = () => {
    //         async function getData() {
    //             firebase.database().ref('contactMail').on('value', (snapshot) => {
    //                 const rawUserData = []
    //                 var snapVal = snapshot.val();
    //                 for (let id in snapVal) {
    //                     rawUserData.push({ ...snapVal[id], id: id })
    //                 }
    //                 console.log("fetched",rawUserData.reverse())
    //                 setData(rawUserData.reverse())
    //             })


    //         }
    //         getData()
    //     }
    //     loadData()
    // }, [])



    useEffect(() => {
        // Fetch data from Firebase
        const fetchData = async () => {
            console.log("techdata function")
            const ref = firebase.database().ref('contactMail');
            ref.on('value', (snapshot) => {
                const rawUserData = [];
                const snapVal = snapshot.val();

                console.log("snapval", snapVal)
                // Check if there's data
                if (snapVal) {
                    for (let id in snapVal) {
                        rawUserData.push({ ...snapVal[id], id });
                    }
                    const reversedData = rawUserData.reverse();
                    console.log("Fetched data:", reversedData);
                    setData(reversedData);
                } else {
                    console.log("No data available in 'contactMail'");
                }
            });
        };

        fetchData();

        // Cleanup listener to avoid memory leaks
        return () => {
            firebase.database().ref('contactMail').off('value');
        };
    }, []);

    console.log("data", data)


    const deleteItem = (id) => {
        Swal.fire(
            'Do you really want to delelte category?',
            '',
            'warning'
        ).then(() => {

            const ItemRef = firebase.database().ref('contactMail/').child(id);
            ItemRef.remove().then(() => {
                Swal.fire(
                    'Deleted Successfull',
                    "",
                    'success'
                )
            })



        })
    }
    return (

        <>
            <SlideMenu title="Edit Catrgories" url="/edit-categories" />
            <Box
                sx={{
                    bgcolor: 'background.default',
                    // width: "100%"
                    display: "flex",
                    flex: 1,
                }}
                className="main-display edit-categories"
            >
                <div className="main-child">
                    <SecondMenu title="Manage orders" url="/orders" />
                    <div className="container smart-card">
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography
                                sx={{ m: 1, color: 'text.primary', }}
                                variant="h4"
                            >
                                Contact Mails
                            </Typography>
                        </Box>

                        <Box sx={{ color: 'text.primary', pb: 10, mt: 3, }} className="table-responsive">
                            <TableContainer sx={{ minWidth: 650, backgroundColor: "#111827" }} component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead sx={{ backgroundColor: "#1f2937" }}>
                                        <TableRow>
                                            <TableCell>Sr No.</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell>Mobile No.</TableCell>
                                            <TableCell>Address</TableCell>
                                            <TableCell>Subject</TableCell>
                                            <TableCell>Message</TableCell>
                                            <TableCell>Time</TableCell>
                                            <TableCell colSpan={3} className="text-center">Opration</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.map((list, index) => (
                                            <TableRow key={index + 1}>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>{list.name}</TableCell>
                                                <TableCell>{list.mail}</TableCell>
                                                <TableCell>{list.phone}</TableCell>
                                                <TableCell>{list.address}</TableCell>
                                                <TableCell>{list.subject}</TableCell>
                                                <TableCell>{list.message}</TableCell>
                                                <TableCell>{TimeStampToDate(list.timeStamp)}</TableCell>
                                                {
                                                    list.mail && <TableCell>
                                                        <a href={`mailto:${list.mail}`}>
                                                            <ReplyIcon color="primary" />
                                                        </a>
                                                    </TableCell>
                                                }
                                                <TableCell>
                                                    <a href={`tel:${list.phone}`}>
                                                        <CallIcon color="success" />
                                                    </a>
                                                </TableCell>
                                                <TableCell onClick={() => deleteItem(list.id)} className="text-center"><i style={{ cursor: 'pointer' }} className="fa fa-trash text-danger"></i></TableCell>
                                                {/* <TableCell style={{ minWidth: 185 }}>{timestamp}</td> */}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>

                    </div>
                </div>
            </Box>
        </>
    )
}

export default ContactMails