import React, { useEffect, useState } from "react";
import {
    Box,
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    Button,
    Typography,
    useMediaQuery,
    Backdrop,
    Dialog,
    DialogContent,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MainCover from "../Components/MainCover";
import { approveUser, fetchUnapprovedUsers, removeUserPfp } from "../util/approveProfile";

const theme = createTheme({
    palette: {
        primary: {
            main: "#1a202c", // Dark background
        },
        secondary: {
            main: "#2d3748", // Secondary dark shade
        },
    },
    typography: {
        fontFamily: "Roboto, sans-serif",
    },
});

const ApproveProfileUI = () => {
    const [users, setUsers] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const getUnapprovedUsers = async () => {
        const data = await fetchUnapprovedUsers();
        setUsers(data);
    };

    useEffect(() => {
        getUnapprovedUsers();
    }, []);

    const handleApprove = async (id) => {
        const data = await approveUser(id)
        getUnapprovedUsers()
    };

    const handleReject = async (id) => {
        const data = await removeUserPfp(id)
        getUnapprovedUsers()
    };

    const handleImageClick = (image) => {
        setSelectedImage(image);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedImage(null);
    };

    return (
        <MainCover>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={false}
            ></Backdrop>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100vh",
                    backgroundColor: theme.palette.primary.main,
                    color: "#fff",
                    padding: isMobile ? 2 : 4,
                }}
            >
                <Typography variant="h4" color="#fff" gutterBottom>
                    Approve Profiles Requests
                </Typography>
                <Box
                >
                    <List>
                        {users.map((user, index) => (
                            <ListItem
                                key={index}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    padding: 2,
                                    backgroundColor: "#2d3748",
                                    borderRadius: 2,
                                    marginBottom: 2,
                                }}
                            >
                                <ListItemAvatar>
                                    <Avatar
                                        src={user.pfp.profilePic}
                                        onClick={() => handleImageClick(user.pfp.profilePic)}
                                        sx={{ cursor: "pointer" }}
                                    />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={user.name}
                                    primaryTypographyProps={{ color: "#fff" }}
                                />
                                <Box sx={{ display: "flex", gap: 2 }}>
                                    <Button
                                        variant="contained"
                                        color="success"
                                        onClick={() => handleApprove(user.id)}
                                    >
                                        Approve
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        onClick={() => handleReject(user.id)}
                                    >
                                        Reject
                                    </Button>
                                </Box>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Box>

            {/* Dialog for Enlarged Image */}
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogContent sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                    <img
                        src={selectedImage}
                        alt="Profile"
                        style={{
                            maxWidth: "100%",
                            maxHeight: "80vh",
                            borderRadius: "8px",
                            boxShadow: "0px 4px 10px rgba(0,0,0,0.2)",
                        }}
                    />
                </DialogContent>
            </Dialog>
        </MainCover>
    );
};

export default ApproveProfileUI;
